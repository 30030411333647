export class I9ReverificationAndRehires {
    id: number;
    FK_I9InfoForm?: Number;
    ENLastName?: string;
    ENFirstName?: string;
    ENMiddleName?: string;
    DateofRehire?: Date;
    DocumentTitle?: string;
    DocumentNumber?: string;
    DocumentExpiryDate?: string;
    ERName?: string;
    ERSignatureData?: any;
    ERSubmittedDate?: Date;
    ERipAddress?: string;
    ESubmittedDate?: Date;
    EipAddress?: string;
    ERApproveOrRejectDate?: Date;
    ERApproveOrRejectComment?: Date;
    EEmailComment?: string;
    AuthName?: string;
    AuthEmail?: string;
    AuthEmailComment?: string;
    IsApproved?: boolean;
    IsActive?: boolean;
    ReportCoverPage?: string;
    CreatedDate?: Date;
    LastName?: string;
    FirstName?: string;
    MiddleName?: string;
    Stage?: Number;
    selectedAction?:string;
    ReverificationUrl?:string;
    IsRecipt?: boolean;
    AdditionalInformation?: string;
    CoverPageInformation?: string;
    I9Reverification_Uploaded_Documents :[];
    AuditLogInfo?: string;
}





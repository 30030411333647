import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Pagination } from './pagination';
import { ApplicationhttpService } from 'app/applicationhttp.service';

@Injectable({
  providedIn: 'root'
})
export class CaselistService {

  _accessToken: any;
  _casesList: any;
  _tokenData: any;

  constructor(private _http: HttpClient,private ser: ApplicationhttpService) { }

  authenticate(a: any, b: any) {
    const body = {
      'username': a,
      'password': b
    }
    return this._http.post(`${this.ser.baseUrl}/api/Everify/AuthenticateEverify`, body);
  }

  refreshToken() {
    const body = {
      'access_token':  localStorage.getItem("LoggedUserID"),
      'user_id': localStorage.getItem("LoggedUserID"),
      'expiry_date': localStorage.getItem("LoggedUserID"),
    }
    return this._http.post(`${this.ser.baseUrl}/api/Everify/AuthenticateEverify`, null);

  }

  getCases(pagination: Pagination) {
    const body = {
      'access_token':localStorage.getItem('access_token_everify'),
      pagination:pagination
    }
    return this._http.post(`${this.ser.baseUrl}/api/Everify/GetCases`, body);
  }

  getCaseDetails(caseId: string) {
    const body = {
      'access_token':localStorage.getItem('access_token_everify'),
      'case_number':caseId
      }
    return this._http.post(`${this.ser.baseUrl}/api/Everify/GetCase`,body);
  }

  submitCaseData(caseId: string) {
    return this._http.post(`${this.ser.baseUrl}/cases/${caseId}/submit`, null);
  }

  public validateToken() {
    return this._http.post(`${this.ser.baseUrl}/authentication/validate`, null);
  }

  getSwaggerJson() {
    return this._http.get(`${this.ser.baseUrl}/swagger-api/swagger.json`);
  }
}
